<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <div>
        <div>
          <b-row class="no-gutters">
            <b-col class="text-left">
              <h1 class="mr-sm-4 header-tablepage">
                RECEIPT DETAIL REF: {{ form.invoice_no }}
              </h1>
            </b-col>
          </b-row>
        </div>
        <div>
          <DetailSection :detail="form" :v="$v.form" />
        </div>

        <div class="p-3 bg-white">
          <div class="mb-2">
            <b-button
              class="btn-select-branch"
              @click.prevent="openProductModal"
            >
              Select Product
              <font-awesome-icon icon="chevron-right" class="ft-14 ml-2" />
            </b-button>
            <span class="text-black ml-3"
              >(Selected Product
              {{ form.product.length | numeral("0,0") }} List)</span
            >
            <template v-if="$v.form.product.$error">
              <span class="ml-2 text-error">
                {{
                  $v.form.product.required
                    ? "Please input all value"
                    : "Please select product at least 1."
                }}
              </span>
            </template>
          </div>
          <ProductSection
            :items="form.product"
            :fields="fields"
            :grandTotal="form.grand_total"
            :v="$v.form.product"
            v-on:deleteProduct="deleteProduct"
            v-on:calculatePrice="calculatePrice"
          />
        </div>
      </div>
    </div>
    <div class="footer-action">
      <b-col class="text-left">
        <b-button @click.prevent="beforePage" class="btn-cancel btn-width"
          >Back</b-button
        >
      </b-col>
      <b-col class="text-right">
        <b-button
          variant="danger"
          class="btn-reject mr-sm-2"
          @click="showModalReject"
        >
          Reject
        </b-button>
        <b-button class="main-color btn-save" @click="acceptReceipt">
          Accept
        </b-button>
      </b-col>
    </div>
    <ProductModal v-on:submitProduct="submitProduct" ref="ProductListModal" />
    <ModalReject ref="modalReject" v-on:confirm="rejectReceipt" />
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import DetailSection from "@/components/receipt/DetailSection";
import ProductSection from "@/components/receipt/ProductSection";
import ProductModal from "./../components/ProductModal.vue";
import ModalReject from "@/components/receipt/ModalReject";
import {
  required,
  requiredIf,
  minValue,
  integer,
} from "vuelidate/lib/validators";

export default {
  components: {
    OtherLoading,
    DetailSection,
    ProductSection,
    ProductModal,
    ModalReject,
  },
  validations() {
    return {
      form: {
        branch_id: {
          required: requiredIf(function () {
            return this.form.branch_id == 0;
          }),
        },
        invoice_no: {
          required,
        },
        product: {
          required: requiredIf(function () {
            return this.form.product.length == 0;
          }),
          $each: {
            quantity: {
              required,
              minValue: minValue(1),
              integer,
            },
            price: {
              required,
              minValue: minValue(1),
              integer,
            },
          },
        },
      },
    };
  },
  data() {
    return {
      isLoading: false,
      id: this.$route.params.id,
      urlKey: this.$route.params.receipt_id,
      form: {
        id: 0,
        receipt_scanner_id: 0,
        receipt_name: "",
        user_guid: "",
        image_url: "",
        status: 0,
        status_name: "",
        branch_id: null,
        branch_name: null,
        point: 0,
        invoice_no: null,
        member_id: "",
        customer_name: "",
        error_detail: null,
        created_time: "",
        updated_time: "",
        product: [],
        grand_total: 0,
        transaction_ref_id: "",
        receipt_time: "",
      },
      fields: [
        {
          label: "Barcode / Name",
          key: "article_no",
        },
        {
          label: "Quantity",
          key: "quantity",
        },
        {
          label: "Price Per Unit (Baht)",
          key: "price",
        },
        {
          label: "Total Price (Baht)",
          key: "grandTotal",
        },
        {
          label: "",
          key: "action",
        },
      ],
      filter: {
        page: 1,
        length: 10,
        start: 0,
        DeleteProductList: [],
        onlyInRedeem: 1,
        search: "",
        selectProductList: [],
      },
      loadingNote: false,
      showBtnDelete: true,
      showBtnVoid: true,
      freezeProductList: [],
      productSelect: [],
    };
  },
  async created() {
    await this.getDetail();
  },
  destroyed() {},
  methods: {
    async getDetail() {
      this.isLoading = true;
      await this.$store.dispatch("getReceiptScanStatusDetail", this.id);
      let data = this.$store.state.receiptscan.stateReceiptScanDetail;
      if (data.result == 1 && data.detail) {
        this.receiptDetail = data.detail;
        this.form = {
          ...data.detail,
          product: [],
          grand_total: 0,
          branch_id: data.detail.branch_id == 0 ? "" : data.detail.branch_id,
          created_time: data.detail.created_time || new Date(),
          transaction_ref_id: data.detail.transaction_ref_id || "",
        };
        this.isLoading = false;
      } else {
        this.isLoading = false;
        this.errorAlert(data.message).then(() =>
          this.$router.push(`/setting/receipt/monitor/${this.urlKey}`)
        );
      }
    },
    selectProduct() {
      this.$refs.modalSelectProduct.show(this.productSelect);
    },
    beforePage() {
      this.$router.push(`/setting/receipt/monitor/${this.urlKey}`);
    },

    async openProductModal() {
      await this.$refs.ProductListModal.show(this.productSelect);
    },
    showModalReject() {
      this.$refs.modalReject.show();
    },
    submitProduct(select) {
      select = select.map((e) => {
        return e.id;
      });

      this.filter.DeleteProductList = this.filter.DeleteProductList.filter(
        (el) => !select.includes(el)
      );

      this.productSelect = select;
      this.form.product = this.form.product.filter((o) =>
        select.some((i) => i === o.id)
      );
      this.filter.page = 1;
      this.getProductSelect();
    },
    async deleteProduct(data) {
      await this.filter.DeleteProductList.push(
        this.form.product[data.index].id
      );
      await this.form.product.splice(data.index, 1);
      this.productSelect = this.productSelect.filter(
        (item) => item != data.item.id
      );
      this.form.product = this.form.product.map((e, index) => {
        return { ...e, index };
      });
      if (this.form.product.length == 0) {
        if (this.filter.page > 1) {
          this.filter.page -= 1;
        }
      }
      this.getProductSelect();
    },
    acceptReceipt() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }

      this.setupForm({ result: 1, errorType: 0 });
    },
    rejectReceipt(data) {
      const { reasonId, note } = data;
      this.setupForm({ result: 0, errorType: reasonId, message: note });
    },
    setupForm({ result, errorType, message }) {
      this.$bus.$emit("showLoading");
      const products = this.form.product.map((product) => {
        return {
          id: product.id,
          quantity: product.quantity,
          price: product.price,
        };
      });
      let payload = {
        result: result,
        errorType: errorType,
        message: message,
        isAdminUpdate: 1,
        detail: {
          requestId: this.form.id,
          invoiceNo: this.form.invoice_no,
          receipt_time: this.form.receipt_time,
          branchId: this.form.branch_id || 0,
          product: [...products],
          grandTotal: this.form.grand_total,
          receiptDate: this.form.created_time,
        },
      };
      this.submit(payload);
    },
    async submit(payload) {
      let data = null;
      await this.$store.dispatch("updateReceiptScanStatus", payload);
      data = this.$store.state.receiptscan.stateReceiptScanDetail;

      if (data.result == 1) {
        this.successAlert().then(() =>
          this.$router.push(`/setting/receipt/monitor/${this.urlKey}`)
        );
      } else {
        this.errorAlert(data.message);
      }
      this.$bus.$emit("hideLoading");
    },
    async getProductSelect() {
      this.isBusy = true;
      this.filter.start = (this.filter.page - 1) * this.filter.length;
      this.filter.selectProductList = this.productSelect.map((e) => {
        return { id: e, point: 0 };
      });
      await this.$store.dispatch("getListProductSelect", this.filter);
      const data = this.$store.state.redeem.productSelectList;
      if (data.result == 1) {
        const newSelected = data.detail.data
          .map((e, index) => {
            const quantity = e.quantity || 1;
            return { ...e, index, quantity, total: quantity * e.price };
          })
          .filter((o) => !this.form.product.some((i) => i.id === o.id));

        this.form.product = this.form.product.concat(newSelected);
        this.rows = data.detail.totalSelectProduct;
        this.calculatePrice();
      } else {
        this.form.product = [];
        this.rows = 0;
      }
      this.isBusy = false;
    },
    calculatePrice() {
      this.form.grand_total = this.form.product
        .map((item) => item.total)
        .reduce((prev, curr) => prev + curr, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  z-index: 2;
  position: fixed;
  bottom: 0;
  left: 256px;
  right: 0;
  padding: 10px;
  background: #fff;
}

.btn-confirm-automation {
  color: #fff;
  border-radius: 0px;
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  width: 30%;
}

@media (min-width: 350px) and (max-width: 991px) {
  .footer-action {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.footer-action {
  margin-left: var(--margin-spacing);
  z-index: 2;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  background: #fff;
  display: flex;
  flex-direction: row;
  transition: margin-left 0.25s, margin-right 0.25s, width 0.25s, flex 0.25s;
}

.btn-reject {
  width: 125px;
}
</style>
