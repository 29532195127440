<template>
  <div class="pl-3 pr-3 pb-3 pt-0">
    <b-row class="bg-white">
      <b-col class="px-0">
        <b-table
          class="mb-0"
          ref="tablecustom"
          responsive
          striped
          hover
          :fields="fields"
          :items="items"
          show-empty
          foot-clone
          empty-text="No matching records found"
        >
          <template v-slot:cell(article_no)="data">
            <b-row>
              <b-col class="text-left">
                <div class="text-name">
                  {{ data.item.name }}
                </div>
                <div>{{ data.item.barcode }}</div>
              </b-col>
            </b-row>
          </template>
          <template v-slot:cell(quantity)="data">
            <InputText
              textFloat=""
              placeholder="quantity"
              type="text"
              v-model="data.item.quantity"
              isRequired
              :isValidate="v.$each.$iter[data.index].quantity.$error"
              @onKeypress="isNumber"
              @onKeyup="calculatePrice(data)"
            />
          </template>
          <template v-slot:cell(price)="data">
            <InputText
              textFloat=""
              placeholder="Price"
              type="text"
              v-model="data.item.price"
              isRequired
              :isValidate="v.$each.$iter[data.index].price.$error"
              @onKeypress="isNumber"
              @onKeyup="calculatePrice(data)"
            />
          </template>
          <template v-slot:cell(grandTotal)="data">
            <div>
              {{ data.item.total | numeral("0,0.00") }}
            </div>
          </template>
          <template v-slot:cell(action)="data">
            <div class="d-flex justify-content-center">
              <b-button
                variant="light"
                class="py-0"
                @click="deleteProduct(data)"
              >
                <font-awesome-icon icon="trash-alt" title="Delete" />
              </b-button>
            </div>
          </template>
          <template #foot(article_no)>
            <span>Grand total (Baht)</span>
          </template>
          <template #foot(quantity)><div></div> </template>
          <template #foot(price)> <div></div> </template>
          <template #foot(grandTotal)>
            <span>{{ grandTotal | numeral("0,0.00") }}</span>
          </template></b-table
        ></b-col
      ></b-row
    >
  </div>
</template>
<script>
export default {
  props: {
    items: {
      required: true,
      type: Array,
    },
    fields: {
      required: true,
      type: Array,
    },
    grandTotal: {
      required: true,
      type: Number,
    },
    v: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    deleteProduct(data) {
      this.$emit("deleteProduct", data);
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;

      if (evt.target.value.length > 9) {
        return evt.preventDefault();
      }
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    calculatePrice(data) {
      data.item.total = data.item.quantity * data.item.price;
      this.$emit("calculatePrice", data.item);
    },
  },
};
</script>

<style lang="scss" scoped>
.text-name {
  font-weight: 600;
  color: #000;
}
:deep(tfoot) {
  background-color: white;
  color: var(--primary-color);
}
</style>
